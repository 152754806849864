.controls-wrapper {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  /* background: rgba(0, 0, 0, 0.6); */
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-content: center;
  column-gap: 6px;
  /* z-index: 1; */
}

.elapsed-time-span {
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  width: 70px;
  margin-left: 8px;
}

.control-icons {
  color: black;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  fontsize: 50;
}

.seek-slider {
  margin-left: 4px;
  margin-right: 6px;
  width: 100%;
  align-self: center;
}

.progress-button {
  color: black;
  height: 16px;
  width: 16px;
  align-self: center;
  z-index: 10;
  overflow: visible;
}

.control-icons:hover {
  color: #fff;
  transform: scale(1);
}

.controls-wrapper span {
  font-family: 'Mabry Pro Regular', 'Calibre', 'Verdana', 'Helvetica',
    sans-serif;
  font-size: 14px;
  line-height: 18px;
  align-self: center;
}

.ui-video-seek-slider {
  position: relative;
}
.ui-video-seek-slider:focus {
  outline: none;
}
.ui-video-seek-slider .track {
  padding: 10px 0;
  cursor: pointer;
  outline: none;
}
.ui-video-seek-slider .track:focus {
  border: 0;
  outline: none;
}
.ui-video-seek-slider .track .main {
  width: 100%;
  height: 2px;
  background-color: black;
  border-radius: 30px;
  position: absolute;
  left: 0;
  top: 8.5px;
  overflow: hidden;
  transition: transform 0.4s;
  outline: none;
}
.ui-video-seek-slider .track .main:focus {
  border: 0;
  outline: none;
}
.ui-video-seek-slider .track .main .buffered {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  transform: scaleX(0.8);
  z-index: 4;
  transform-origin: 0 0;
  transition: 0.5s transform;
}
.ui-video-seek-slider .track .main .seek-hover {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 3;
  transform: scaleX(0.6);
  transform-origin: 0 0;
  opacity: 0;
  transition: opacity 0.4s;
}
.ui-video-seek-slider .track .main .connect {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 5;
  left: 0;
  transform: scaleX(0.13);
  transform-origin: 0 0;
}
/* .ui-video-seek-slider .track.active .main {
  transform: scaleY(2);
} */
.ui-video-seek-slider .thumb {
  pointer-events: none;
  position: absolute;
  top: 2px;
  z-index: 6;
}
.ui-video-seek-slider .thumb .handler {
  transition: transform 0.2s;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transform: scale(0.4);
}
.ui-video-seek-slider .thumb.active .handler {
  background-image: url('../../../assets/icons/seek-button.svg');
  opacity: 1;
  transform: scale(1);
}
/* .ui-video-seek-slider .hover-time {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 18px;
  font-size: 16px;
  color: #ddd;
  top: -25px;
  left: 0;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateX(150px);
  pointer-events: none;
}
.ui-video-seek-slider .hover-time.active {
  opacity: 1;
}
.ui-video-seek-slider:hover .track .main .seek-hover {
  opacity: 1;
} */

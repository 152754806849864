.hp-headings-container {
  margin-bottom: 16px;
  flex: 1;
}

.hp-headings-container p {
  margin-top: 16px;
  font-family: 'Mabry Pro Medium', sans-serif;
  font-size: 12px;
}

.hp-headings-title {
  display: flex;
  justify-self: left;
  align-self: flex-start;
  text-align: left;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  font-family: 'Mabry Pro Black';
  font-size: 41px;
  line-height: 47px;
}

.hp-headings-subtitle {
  display: flex;
  margin-top: 10px;
  font-family: 'Mabry Pro Medium', sans-serif;
  font-size: 18px;
  line-height: 21px;
}

@media (min-width: 660px) {
  .hp-headings-container {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hp-headings-title {
    justify-self: center;
    align-self: center;
    text-align: center;
    flex-direction: column;
    font-family: 'Mabry Pro Bold', sans-serif;
    font-size: 90px;
    line-height: 100px;
    justify-content: center;
    align-items: center;
  }

  .hp-headings-subtitle {
    margin-top: 10px;
    font-family: 'Mabry Pro Medium', sans-serif;
    font-size: 30px;
    line-height: 36px;
  }

  .hp-headings-info {
    display: flex;
    text-align: center;
  }

  .lang-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hp-headings-container p {
    margin-top: 16px;
    font-family: 'Mabry Pro Medium', sans-serif;
    font-size: 15px;
  }

  .lang-section-subcontainer {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lang-selector button {
  }
}

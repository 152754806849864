.vertical-list ul {
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 20px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  align-content: left;
}

.vertical-list li {
  display: flex;
  border-top: 1px solid white;
  line-height: 68px;
  flex-grow: 1;
  justify-content: left;
  justify-items: left;
  align-items: center;
  min-height: 70px;
}

.poi-list ul {
  padding: 10px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  align-content: left;
}

.poi-list li {
  display: flex;
  border-top: 1px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
  justify-content: left;
  justify-items: left;
  align-items: center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --orange-color: #f0ddcd;
  --green-color: #cfddbb;
  --yellow-color: #f2e9bb;
  --gray1-color: #222222;
  --gray2-color: #c4d4d5;
}

div button {
  border: none;
  background: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.orange-color {
  color: var(--orange-color);
}

.gray1-color {
  color: var(--gray1-color);
}

.green-color {
  color: var(--green-color);
}

.gray2-color {
  color: var(--gray2-color);
}

.yellow-color {
  color: var(--yellow-color);
}

.orange-bg-color {
  background-color: var(--orange-color);
}

.gray1-bg-color {
  background-color: var(--gray1-color);
}

.green-bg-color {
  background-color: var(--green-color);
}

.gray2-bg-color {
  background-color: var(--gray2-color);
}

.yellow-bg-color {
  background-color: var(--yellow-color);
}

@font-face {
  font-family: 'Mabry Pro Black';
  src: local('Mabry Pro Black'),
    url(./assets/fonts/MabryPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Mabry Pro Regular';
  src: local('Mabry Pro Regular'),
    url(./assets/fonts/MabryPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Mabry Pro Medium';
  src: local('Mabry Pro Medium'),
    url(./assets/fonts/MabryPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Mabry Pro Bold';
  src: local('Mabry Pro Bold'),
    url(./assets/fonts/MabryPro-Bold.ttf) format('truetype');
}

#root {
  height: 100%;
}

html {
  height: 100%;
  /*position: -webkit-sticky;*/ /* Safari */
  /*position: sticky;*/
  top: 0;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  /* position: fixed; */
  font-family: -apple-system, BlinkMacSystemFont, 'Mabry Pro Regular', 'Calibre',
    'Verdana', 'Helvetica', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  flex-grow: 1;
}

.page-parent {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

header,
main,
footer {
  flex-shrink: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.map-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.map-legend {
  color: white;
}

.map-legend-header {
  font-family: 'Mabry Pro Medium', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: white;
}

.items-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 6px;
  padding-bottom: 16px;
}

.push-down {
  margin-top: auto;
}

.top-border {
  border-top: 1px solid white;
  margin-bottom: 16px;
}

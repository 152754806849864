.menu-modal {
  position: absolute;
  flex-direction: column;
  align-self: flex-end;
  justify-self: flex-end;
  flex-grow: 1;
  width: clamp(320px, 100%, 620px);
  top: 0;
  right: 0;

  height: 100vh;
  overflow-y: hidden;
  min-height: 100%;

  background-color: #222222;

  padding-left: 16px;
  padding-right: 16px;

  z-index: 11; /* was 3 */
}

.link-button {
  color: #fff;
  text-decoration: none;
  font-family: 'Mabry Pro Medium', sans-serif;
  font-size: 29px;
  flex-grow: 1;
}

.poi-list-item {
  color: #000;
  text-decoration: none;
  font-family: 'Mabry Pro Medium', sans-serif;
  font-size: 19px;
  flex-grow: 1;
}

.no-border {
  border-top: none !important;
}

.menu-modal a {
  color: #fff;
  text-decoration: none;
  font-family: 'Mabry Pro Medium', sans-serif;
  font-size: 29px;
}

.closeicon: {
  fill: #fff;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

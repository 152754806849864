.detail-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4 / 3;
}

.image-loader,
.image-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  align-self: center;
  justify-self: center;
}
.image-loader {
  margin: 60px auto;
  /* margin: 0; */
  font-size: 10px;

  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@media (min-width: 620px) {
  .detail-image {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 4 / 3;
  }
}
